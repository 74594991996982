<template>
	<div class="map-box" v-loading.fullscreen.lock="loading">
		<div id="boardContainer"></div>
		<InfomationForm
			ref="infomationForm"
			v-show="showInfomationForm"
			:currentPosList="currentPosList"
			@closeList="closeList"
		></InfomationForm>
	</div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader'
import InfomationForm from './InfomationForm.vue'
import { isBlank } from '@/utils/validate'
import { apiGetViewDeviceList } from '@/apis/runBoard.api.js'
import { apiStaffsCity } from '@/apis/account.api.js'
import { AMapKey } from '@/config'

export default {
	name: 'MapBg',
	components: { InfomationForm },
	props: {},
	data() {
		return {
			map: null,
			showInfomationForm: false,
			AMap: null,
			currentViewList: [], //当前视野范围内的列表
			currentPosList: [], //当前点击的点存在的设备列表
			loading: false,
			infoWindow: null, //信息窗体
			selectedItem: null, //当前点击的列表项
		}
	},
	created() {},
	mounted() {
		// this.initAMap();
	},
	computed: {
		tenantId() {
			return this.$store.getters.userInfo.tenantId || null
		},
	},
	methods: {
		// 初始化地图
		initAMap(list) {
			AMapLoader.load({
				key: AMapKey,
				version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
				plugins: [
					'AMap.MarkerCluster',
					'AMap.InfoWindow',
					'new AMap.Marker',
					'AMap.Pixel',
					'AMap.Size',
					'AMap.Icon',
				], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
			})
				.then(async AMap => {
					this.AMap = AMap
					// 初始化地图
					this.map = new AMap.Map('boardContainer', {
						viewMode: '2D', //  是否为3D地图模式
						zoom: 13, // 初始化地图级别
						resizeEnable: true, //是否监控地图容器尺寸变化
					})

					this.renderMarker(list)
				})
				.catch(e => {
					console.log(e)
				})
		},
		// 渲染地图标记点
		async renderMarker(list, isClear) {
			if (isClear) {
				this.map && this.removeAllOverlay()
				this.currentViewList = []
			}
			this.currentViewList = [...this.currentViewList, ...list]
			this.infoWindow = new this.AMap.InfoWindow({
				isCustom: true,
			})
			//  可标记点坐标
			const lnglats = (list || []).filter(item => {
				return !isBlank(item.latitude) && !isBlank(item.longitude)
			})
			if (lnglats.length) {
				for (let i = 0; i < lnglats.length; i++) {
					let markerIcon = new this.AMap.Icon({
						size: new this.AMap.Size(34, 40),
						image: require(`@/assets/imgs/board/${lnglats[i].runningState}.png`),
					})
					let marker = new this.AMap.Marker({
						position: [lnglats[i].longitude, lnglats[i].latitude],
						icon: markerIcon,
						map: this.map,
					})
					marker.on('click', e =>
						this.markerClick({
							event: e,
							currentClickItem: lnglats[i],
						}),
					)
				}
			} else {
				//  如果当前默认第一页设备全部无坐标，则默认取登录人所在地展示地图
				try {
					await apiStaffsCity({ tenantId: this.tenantId }).then(res => {
						this.map.setCity(res.additionalInfo.cityName || '')
					})
				} catch (e) {
					console.log(e)
				}
			}
			// 自动调整视野覆盖标记点
			this.map.setFitView()
		},
		// 标记点击事件
		async markerClick(obj) {
			const { longitude, latitude } = obj.currentClickItem
			this.map.setCenter([longitude, latitude], true, 100)
			// 判断当前坐标点存在多个设备还是单个设备
			let posArr = this.currentViewList.filter(item => {
				return (
					item.longitude == obj.currentClickItem.longitude && item.latitude == obj.currentClickItem.latitude
				)
			})
			// 避免同一标记点重复点击
			if (JSON.stringify(posArr) === JSON.stringify(this.currentPosList) && this.showInfomationForm) {
				return false
			}
			this.currentPosList = this._.cloneDeep(posArr)
			// 存在单个设备
			if (this.currentPosList.length == 1) {
				await this.$refs.infomationForm.getDeviceDetail(this.currentPosList[0].iotDeviceId)
			}
			this.loading = false
			this.showInfomationForm = true
			this.infoWindow.setContent(this.$refs.infomationForm.$el)
			// 点击锚点定位到中心
			// this.map.setCenter(obj.event.target.getPosition());
			let pos = null
			if (obj.event) {
				pos = obj.event.target.getPosition()
			} else {
				pos = [obj.currentClickItem.longitude, obj.currentClickItem.latitude]
			}
			this.infoWindow.open(this.map, pos)
		},
		// 获取视野内设备
		getCurrentViewDevice() {
			return new Promise(resolve => {
				let bounds = this.map.getBounds()
				// 地图暂不区分设备展示，展示的为当前视野范围内所有设备
				apiGetViewDeviceList({
					firstCategory: '0',
					runningState: '0',
					eastLongitude: bounds.northEast.lng,
					northLatitude: bounds.northEast.lat,
					westLongitude: bounds.southWest.lng,
					southLatitude: bounds.southWest.lat,
				})
					.then(res => {
						this.currentViewList = res || []
						console.log(res, 'res-视野范围内设备')
						// 标记点和信息窗体
						this.infoWindow = new this.AMap.InfoWindow({
							// anchor: "bottom-center",
							isCustom: true,
							// offset: new this.AMap.Pixel(0, -30),
						})
						let lnglats = res || [] //标记点坐标
						for (let i = 0; i < lnglats.length; i++) {
							let markerIcon = new this.AMap.Icon({
								size: new this.AMap.Size(34, 40),
								image: require(`@/assets/imgs/board/${lnglats[i].runningState}.png`),
							})
							let marker = new this.AMap.Marker({
								position: [lnglats[i].longitude, lnglats[i].latitude],
								icon: markerIcon,
								map: this.map,
							})
							marker.on('click', e =>
								this.markerClick({
									event: e,
									currentClickItem: lnglats[i],
								}),
							)
						}

						// 当前为点击造成的视野范围变化，且本次请求为对应请求
						let center = this.map.getCenter()
						if (
							this.selectedItem &&
							center.lng + 0.000002 >= this.selectedItem.longitude &&
							center.lng - 0.000002 <= this.selectedItem.longitude &&
							center.lat + 0.000002 >= this.selectedItem.latitude &&
							center.lat - 0.000002 <= this.selectedItem.latitude
						) {
							this.markerClick({
								currentClickItem: this.selectedItem,
							})
							this.selectedItem = null
						}
					})
					.finally(() => {
						this.loading = false
						resolve()
					})
			})
		},
		// 定位
		async setDeviceLocation(item) {
			this.markerClick({
				currentClickItem: item,
			})
			this.selectedItem = item
			this.showInfomationForm = true
		},
		closeList() {
			this.showInfomationForm = false
			this.infoWindow.close()
			this.currentPosList = []
		},
		// 清除地图标记点和覆盖物
		removeAllOverlay() {
			this.map.clearMap()
		},
	},
}
</script>
<style lang="scss" scoped>
.map-box {
	height: 100%;
	#boardContainer {
		height: 100%;
	}
}
</style>
