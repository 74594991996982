<template>
	<div class="search-box">
		<gc-custom-search
			class="run-board-search"
			:key-word.sync="keyWord"
			:key-word-option="keyWordOption"
			keyWordWidth="120px"
			width="620px"
			:search.sync="search"
		></gc-custom-search>
		<div class="search-icon" @click="searchList">
			<i class="el-icon-search"></i>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Search',
	props: {},
	data() {
		return {
			search: '',
			keyWord: 'deviceNo',
			keyWordOption: [
				{
					label: '设备编号',
					value: 'deviceNo',
				},
				{
					label: '客户/站点名称',
					value: 'userName',
				},
				{
					label: '设备地址',
					value: 'address',
				},
			],
		}
	},
	created() {},
	mounted() {},
	methods: {
		searchList() {
			this.$parent.$refs.category.$refs.list.showList = true
			this.$parent.$refs.category.$refs.list.updateDeviceList(1, true, {
				[this.keyWord]: this.search,
			})
		},
	},
}
</script>
<style lang="scss" scoped>
.search-box {
	position: absolute;
	top: 20px;
	left: 20px;
	display: flex;
	z-index: 100;
	.run-board-search {
		background: #fff;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		border-right-color: #fff;
	}
	.search-icon {
		padding: 11px 11px;
		background: #4d6bff;
		border-top-right-radius: 8px;
		border-bottom-right-radius: 8px;
		cursor: pointer;
		i {
			color: #fff;
			font-size: 18px;
		}
	}
}
</style>
